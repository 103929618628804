@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,900');
@import url('https://cdnjs.cloudflare.com/ajax/libs/balloon-css/0.5.0/balloon.css');
@import url('./assets/modules/normalize.css');
@import url('./assets/modules/animate.css');
/* @import url('./assets/modules/owl.carousel.min.css'); */
@import url('./assets/modules/owl.theme.default.min.css');

* {
  outline-width: 0;
  font-family: 'Montserrat', 'Helvetica Neue' Arial, Helvetica, sans-serif;
}

body,
html {
  background: #eee;
  overflow-x: hidden;
  font-family: 'Montserrat', 'Helvetica Neue' Arial, Helvetica, sans-serif;
}

body.night,
html.night {
  background: #333;
}

.flex {
  display: flex;
}

.centre {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  height: 120px;
}

.visible {
  display: block !important;
}

nav main svg {
  color: palevioletred;
}

.container {
  background-color: #161733;
  min-height: 100vh;
  color: white;
  overflow-y: scroll;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 105px;
  height: 100vh;
  background: #eee;
  z-index: 105;
  overflow-y: scroll;
  text-align: center;
}

nav main {
  padding: 0;
  margin: 0;
  list-style: none;
}

nav main a {
  display: block;
  width: 68px;
  height: 84px;
  font-size: 18px;
  color: #444;
  background: #eee;
  transition: 0.3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.animated {
  animation-duration: 500ms;
}

.logo svg {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}

nav main a i {
  transition: 0.3s ease;
}

nav main a:hover {
  background: #fff;
}

.selected {
  background: #fff;
  box-shadow: inset 4px 0 0 palevioletred;
}

nav main a:hover svg {
  margin-left: 4px;
}

nav main:last-child {
  border-bottom: none;
}

#Header {
  box-sizing: border-box;
  height: 50%;
  background-color: palevioletred;
  background-size: cover;
  /* position: relative; */
  overflow: hidden;
}
#Header:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  top: 100%;
  left: 50%;
  margin-left: 12.5px;
  margin-top: -25px;
  transform: rotate(45deg);
  background: var(--bg);
}

#Header .headerDarken {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  height: 100%;
}

.headerMe {
  position: absolute;
  bottom: 80px;
  display: flex;
  left: 45%;
}

.headerMe img {
  border-radius: 50%;
  background-origin: content-box;
  background-position: bottom center;
  background-blend-mode: hard-light;
  width: 200px;
  height: 200px;
}

#wrapper {
  width: 100%;
  margin: 0 auto;
  padding-left: 68px;
}

#container #wrapper {
  padding-top: 20px;
}

.content-container {
  position: relative;
  width: 70%;
  margin: -20px auto 20px auto;
  padding-top: 2em;
  color: rgb(199, 194, 194);
  font-weight: 600;
}

p {
  color: ghostwhite;
  line-height: 2;
  font-weight: 300;
  font-size: 1rem;
  padding: 0.7rem;
}

.name {
  text-transform: uppercase;
  font-size: 1.5em;
  letter-spacing: 1px;
  font-weight: 600;
  color: #fff;
}

.typewriter {
  color: slateblue;
}

h4 {
  color: #fff;
  font-weight: 700;
  font-size: 0.6em;
}

.home-btn {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background: inherit;
  border: 1px solid rgb(102, 101, 101);
  padding: 1em;
  border-radius: 30px;
  cursor: pointer;
  transition: 300ms ease-in-out;
}

.home-btn:hover {
  background: palevioletred;
}

.socials {
  margin: 3em;
}

.socials a {
  text-decoration: none;
  margin-right: 0.8em;
  color: rgba(255, 255, 255, 0.2);
  padding: 1.2rem;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: 200ms ease-in;
  animation-name: blinking;
  animation-duration: 5s;
  animation-timing-function: ease-out;
  font-size: 1.2rem;
}

.socials svg {
  color: rgb(102, 101, 101);
}

b {
  color: #fff;
  font-weight: 700;
}

.content-container svg {
  color: #fff;
}

.about {
  width: 80%;
  margin: 2em auto;
  font-size: 14px;
}

.flex-1 {
  flex: 1;
}

.m-b-20 {
  margin-bottom: 20px;
}

.hr {
  border: 0.5px solid rgb(102, 101, 101);
  margin: 2em 0;
}

.skills h4 {
  font-size: 1em;
}

.testimonials {
  justify-content: center;
  text-align: center;
  margin: auto;
}

.testimonials h4 {
  font-size: 1em;
}

.testimonials div {
  display: flex;
  text-align: center;
}

.testimonials-resp {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1.5em;
}

.owl-dot {
  text-align: center;
}

cite {
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  margin-top: 2em;
  color: #fff;
}

figure {
  flex: 1;
  text-align: center;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 2em auto;
  /* background-image: url('../img/avatar.jpeg'); */
  background-position: center;
  background-size: cover;
}

section {
  height: 100%;
  background-color: var(--bg);
  color: #fff;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  position: relative;
  display: none;
}

.resume ul {
  list-style: none;
  margin: 2em 0 1.5em 0;
  padding: 0;
  position: relative;
}

.resume-stats ul:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 100%;
  border-right: 1px solid #fff;
}

.resume-stats ul li {
  margin: 30px;
  position: relative;
}

.resume-stats ul li span {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  height: 100%;
  border-right: 1px solid #fff;
}

.resume-stats ul li span:before,
.resume-stats ul li span:after {
  background: palevioletred;
  content: '';
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  position: absolute;
  border-radius: 50%;
  top: -2px;
  left: -5px;
}

.resume-stats .desc {
  font-weight: 700;
  font-size: 14px;
  color: palevioletred;
  margin-bottom: 0.6em;
}

.resume-stats .date {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 1em;
  color: #fff;
}

.resume-stats .resume-body {
  font-size: 14px;
  line-height: 1.5;
}

.bold {
  font-weight: 700;
}

.white {
  color: #fff;
}

.m-right-10 {
  margin-right: 10px;
}

.header-text {
  font-size: 1.4em;
  margin-bottom: 2em;
}

/*  */

.blog-content {
  width: 100%;
}

.blog-asset {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.blog-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.blog-asset:hover > .overlay {
  opacity: 1;
  height: 100%;
}

.blog-details {
  margin: 1.5em 0;
}

.blog-date {
  color: var(--pink);
  margin-left: 10px;
  font-size: 13px;
}

.blog-title {
  font-weight: 700;
  margin-top: 0.1em;
  color: #fff;
  font-size: 1.2rem;
  line-height: 3;
}

.blog-body {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1em;
  color: #fff;
  opacity: 0.7;
}

.clients h4 {
  color: #fff;
  font-size: 1em;
}

.client {
  text-align: center;
  padding: 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  margin-right: 1em;
  margin-bottom: 1em;
  font-size: 1em;
}

.client:last-child {
  margin-right: 0;
}

.client .pro-count {
  color: palevioletred;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 2em;
}

.map {
  width: 100%;
  height: 300px;
  /* background-image: url('../img/1.jpg'); */
  background-position: center;
  background-size: cover;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.4);
  margin-bottom: 1em;
}

.contact-btn {
  padding: 1em 2em;
  margin: 1.5em 0;
  border-radius: 2em;
  background: inherit;
  border: 1px solid #fff;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.9em;
  transition: all 0.3s ease-in-out;
}

.contact-btn:hover {
  background: palevioletred;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

/* .overlay .description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.overlay .description h4 {
  font-size: 16px;
  margin-bottom: 0;
}

.overlay .description p {
  font-size: 14px;
  margin-top: 0;
  font-weight: 600;
}

.skill-name {
  text-transform: uppercase;
  font-size: 0.9em !important;
}

.progress-container {
  width: 80%;
  height: 4px;
  border-radius: 5px;
  background: var(--grey);
}

.progress-percent {
  height: 100%;
}

.skill-1 {
  width: 90%;
  background: coral;
}

.skill-2 {
  width: 80%;
  background: var(--blue);
}

.skill-3 {
  width: 90%;
  background: var(--brown);
}

.skill-4 {
  width: 85%;
  background: var(--teal);
}

.service {
  width: 80%;
  text-align: center;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  transition: all 0.5s ease-in-out;
}

.service:hover {
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
}

.service h4 {
  color: #fff;
  font-size: 1em;
  font-weight: 600;
}

.service-wrapper {
  padding: 2em;
}

.service svg {
  color: var(--blue);
}

.error-page {
  display: flex;
  justify-content: center;
  align-content: center;
  text-transform: uppercase;
  flex-direction: column;
  margin-top: 25vh;
}

.error-page > div {
  text-align: center;
}

.error-page > div:first-child {
  font-weight: 900;
  font-size: 6rem;
  color: rgb(202, 9, 73);
}

.error-page > div:last-child {
  font-weight: 700;
  font-size: 2rem;
}

.grid {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
}

.grid_item {
  position: relative;
  width: 45%;
  justify-content: space-between;
  margin-bottom: 40px;
}
.grid_item:hover {
  box-shadow: 5px 5px 5px 10px rgb(0, 9, 30);
}

.grid_item img {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(7, 5, 6, 0.637);
}

.grid_item:hover .overlay {
  opacity: 1;
}

.grid_item .description {
  color: white;
  font-size: 20px;
  /* position: absolute; */
  top: 50%;
  left: 50%;
  /* -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  text-align: center;
  margin-top: 30px;
}

.description .type {
  color: white;
}

.description a {
  font-size: 24px;
  margin-left: 10px;
  color: #1b1b24 !important;
}

.description a i:hover {
  color: #1b1b24;
}

/* Media Queries */

.section {
  margin: 0 auto;
}

.contact-section {
  padding-top: 2vh;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.contact {
  width: 80%;
}
.svg {
  width: 100%;
  height: 300px;
}

form {
  width: 50%;
  border: 1px solid #24023f;
  padding: 20px;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* box-shadow: inset 0 2px 4px 0 #10011b, inset -2px -2px 6px 0px #10011b; */
  padding: 1.5rem;
  box-sizing: border-box;
}

label,
input,
textarea {
  display: block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  border-radius: 10px;
}

label {
  font-weight: 900;
  font-size: 1.2rem;
}

textarea,
input {
  background-color: #6b63ff3d;
  color: white;
  font-weight: 700;
  outline: none;
  border: none;
  width: 90%;
  padding: 0.5rem;
}

textarea:focus,
input:focus {
  background-color: #1b1b24;
}

.submit {
  width: 90%;
  height: 45px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2.5px;
  font-weight: 500;
  background-color: #2d135a;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  /* font-size: 11px; */
  margin-top: 1rem;
  color: #fff;
  font-weight: 700;
}

.submit:hover {
  background-color: #fff;
  color: #2d135a;
}

.profile {
  width: 100%;
  height: auto;
  /* border: #9984a9 solid 2px; */
  margin-bottom: 2rem;
  border-radius: 7px;
}

.parent {
  background-color: #161733;
}

#home {
  /* clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%); */
  background-image: linear-gradient(#161733, #121322cc, #02030e5e),
    url('./assets/img/bg.jpg');
  background-position: center;
  background-size: cover;
  position: relative;
  padding-left: 5rem;
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.row-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 90%;
  box-sizing: border-box;
}

.my-image {
  width: 45%;
}

.content-me {
  width: 90%;
  margin-top: -7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-content: center;
  padding-left: 2rem;
}

.intro-text {
  font-weight: 700;
}

.my-name,
.hello {
  font-size: 1.9rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin-bottom: -1rem;
}
.hello {
  text-align: left;
  font-size: 2.2rem;
  font-weight: 700;
}
.my-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.contact-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  overflow-y: scroll;
}

.contact {
  margin-bottom: 1rem;
}

label {
  color: white;
}

a {
  text-decoration: none;
  color: white;
}
.socials a {
  background-color: palevioletred;
}
.socials svg {
  color: white;
}

.socials a:hover {
  background-color: transparent;
}

.grid_item:nth-child(1),
.grid_item:nth-child(3) {
  animation-name: moveInLeft;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}
.grid_item:nth-child(2),
.grid_item:nth-child(4) {
  animation-name: moveInRight;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}
.grid_item:nth-child(5) {
  animation-name: moveInDown;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}

@media only screen and (max-width: 1300px) {
  #home {
    padding-left: 0;
  }

  .row-flex {
    width: 90%;
    box-sizing: border-box;
    justify-content: center;
  }

  .my-image {
    width: 45%;
  }

  .content-me {
    width: 45%;
    margin-left: 5rem;
    box-sizing: border-box;
    font-size: 1rem;
  }
  .my-title {
    font-size: 1.1rem;
  }
  .hello {
    font-size: 1.7rem;
    font-weight: 700;
  }
  .my-name {
    font-size: 1.5rem;
  }
  .grid {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .grid_item {
    position: relative;
    width: 47%;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .overlay .description p {
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
    line-height: 1.4;
  }

  .socials {
    margin: 2rem 0;
  }
}
@media only screen and (max-width: 1050px) {
  .grid {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .grid_item {
    position: relative;
    width: 47%;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .overlay .description p {
    font-size: 10px;
    text-align: center;
    margin-top: 8px;
    line-height: 1.1;
  }
}

@media only screen and (max-width: 1020px) {
  .row-flex {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }

  .my-image {
    width: 100%;
    margin-top: 1.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .profile {
    width: 60%;
    height: auto;
    border: #9984a9 solid 2px;
    border-radius: 10px;
  }

  .content-me {
    width: 80%;
    margin-top: 2rem;
    text-align: center;
  }

  .intro-text {
    font-weight: 700;
  }

  .my-name,
  .hello {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: -1rem;
  }
  .my-title {
    font-size: 1.3rem;
  }
  #home {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    padding-bottom: 2rem;
  }
  form {
    width: 90%;
  }

  #contact #wrapper {
    padding-left: 20px;
  }

  .header-text {
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .socials {
    display: flex;
    margin: 1rem auto;
  }
}

@media only screen and (max-width: 720px) {
  .flex {
    flex-direction: column;
  }

  .blog-title {
    line-height: 1.4;
  }
  .blog-asset {
    width: 90%;
  }

  .blog-title {
    margin: 0.6em 0;
  }

  .container {
    overflow-y: scroll;
  }

  .grid {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 80%;
  }

  .grid_item {
    position: relative;
    width: 90%;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  /* 
  .grid_item .overlay {
    opacity: 1;
    height: 100%;
  } */
  .grid_item:nth-child(5) {
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-timing-function: ease-out;
  }
}

.cancel {
  font-size: 2rem;
  padding: 0;
  text-align: right;
  margin: 0 10px;
  display: none;
  cursor: pointer;
  position: absolute;
  top: 10%;
  right: 2%;
  z-index: 5000;
}

@media only screen and (max-width: 620px) {
  .content-container {
    width: 85%;
  }
  .flex-1 {
    margin-bottom: -20px;
  }
  form {
    border-radius: 10px;
  }
  #contact #wrapper {
    padding: 0;
  }
  .cancel {
    display: block;
    position: fixed;
    top: 10px;
  }
  nav {
    width: 45px;
    display: none;
  }
  nav main a {
    width: 45px;
    height: 84px;
  }
  #home {
    padding: 1rem;
  }
  .content-me {
    width: 100%;
    padding: 0.5rem;
  }
  .profile {
    width: 90%;
    height: auto;
  }
  .intro-text {
    font-weight: 500;
  }
  .my-name {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .hello {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 1px;
    margin-left: -10px;
  }
  .my-title {
    font-weight: 500;
    font-size: 1rem;
  }
  .content-me {
    margin-top: 0;
    padding-top: 0;
    margin-left: 0;
  }
  #wrapper {
    padding-left: 0px;
  }
  p {
    line-height: 1.6;
  }
  .resume-stats {
    font-weight: 400;
  }
  .resume-stats .date {
    font-weight: 500;
  }

  .svg {
    height: 150px;
    width: 100%;
  }
  form {
    width: 100%;
    margin-top: -1rem;
  }
  label,
  textarea,
  input {
    font-weight: 400;
    font-size: 1rem;
  }
  .submit {
    width: 100%;
  }
  .error-page > div:first-child {
    font-weight: 600;
    font-size: 3rem;
    color: rgb(202, 9, 73);
  }

  .error-page > div:last-child {
    font-weight: 600;
    font-size: 1rem;
    color: rgb(146, 142, 142);
  }
  .grid {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 95%;
  }

  .grid_item {
    position: relative;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .grid_item .overlay {
    /* display: none; */
    opacity: 1;
    height: 100%;
  }

  .socials a {
    padding: 0.5rem;
    font-size: 1.5rem;
  }
  .description .type,
  .description a {
    display: none;
  }
  .description h4 {
    margin-bottom: 30px !important;
    font-size: 1.5rem !important;
    margin-top: 20%;
  }
}

/** Contact */
.contact-me {
  box-shadow: 0px 8px 12px 2px rgba(0, 0, 0, 0.15);
  width: 80%;
  margin: 3rem auto;
  height: 70vh;
  display: flex;
  box-sizing: border-box;
}
form {
  background-color: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  margin: 0 auto;
  align-items: center;
}
.contact-me h2 {
  font-size: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  color: #6a0f4973;
}

.sideNote {
  width: 50%;
  background: linear-gradient(
      to right top,
      #6d084873,
      #7907606c,
      #73097c36,
      #5d079b52,
      #4b1fc36c,
      #968adb7e,
      #9190e259,
      #8e96e967
    ),
    url('./assets//img//mail.webp') no-repeat center;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  font-family: 'Quicksand', sans-serif;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.sideNote p {
  word-spacing: 1.2;
  line-height: 1.4;
  font-size: 24px;
}

.sideNote img {
  top: 20%;
  left: 20%;
  position: absolute;
  height: 250px;
}
@media (max-width: 1200px) {
  .contact-me {
    width: 100%;
  }
}
@media (max-width: 950px) {
  .sideNote img {
    height: 200px;
  }
}
@media (max-width: 800px) {
  .contact-me {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    width: 100%;
  }
  form {
    width: 100%;
    padding: 1rem;
  }
  .sideNote {
    display: none;
  }
}

#about {
  position: relative;
}

/* @keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-1300px);
  }
  80% {
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(1300px);
  }
  80% {
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInDown {
  0% {
    opacity: 0;
    transform: translateY(1300px);
  }
  80% {
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-1300px);
  }
  80% {
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes blinking {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.8;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.mainMenu {
  position: fixed;
  background: #161733;
  width: 105%;
  top: 0%;
  height: 100vh;
  padding-top: 2.5rem;
  animation-duration: 3s;
  top: -30px;
}

.mainMenu li {
  list-style: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  /* margin: 1rem auto; */
}

.blogText {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  padding-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.blog_grid {
  box-shadow: 5px 5px 5px 10px #161523;
  margin-bottom: 40px !important;
}
.blog_grid:hover {
  box-shadow: 5px 5px 5px 10px #04003a;
  background: rgb(0, 9, 30);
  margin-bottom: 40px !important;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: palevioletred;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

.nav-name {
  color: palevioletred;
}
